import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import * as React from 'react';
import { SVGProps } from 'react';
const SvgTwitterNew = function (props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="32" height="32" rx="4" fill="#A4B3FF"/>
      <path d="M25.3544 25.8549L18.0377 14.9505L18.0502 14.9607L24.6473 7.14502H22.4427L17.0685 13.5064L12.8007 7.14502H7.0189L13.8498 17.3258L13.849 17.3249L6.64453 25.8549H8.84912L14.824 18.7775L19.5726 25.8549H25.3544ZM11.9272 8.84592L22.1931 24.154H20.4461L10.1719 8.84592H11.9272Z" fill="#262A4B"/>
    </svg>
  );
};
export default SvgTwitterNew;
